<template>
    <v-footer
    padless class="justify-end"
    color="#11b8b8">

    <v-dialog
       max-width="400"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="#11b8b8"
          dark
          elevation="0"
          v-bind="attrs"
          v-on="on"
        >
          KISRA Tracking Project | v{{ versions[versions.length - 1].version }} ({{ versions[versions.length - 1].date }})
        </v-btn>
      </template>

      <v-sheet class="py-3 px-5 overflow-auto" max-height="500">
        <h3 class="mb-2">Version log</h3>
        <div class="d-flex flex-column" style="gap: 16px">
          <div
              v-for="(v, i) in reversedVersions"
              :key="i"
          >
            <div class="d-flex" style="gap: 8px">
              <div class="chip-version">v{{v.version}}</div>
              <div>({{ v.date }})</div>
            </div>
            <div
                v-for="(desc, ind) in v.description"
                :key="ind"
            >
              - {{ desc }}
            </div>
          </div>
        </div>
      </v-sheet>
    </v-dialog>
    </v-footer>
</template>

<script>
export default {
  data () {
    return {
      versions: [
        {
          version: "1.1",
          date: "2022/05/09",
          description: [
              "เพิ่มการคำนวณสัปดาห์สำหรับฟอร์ม",
              "เปลี่ยนชื่อใน menu tab และ ชื่อหัวข้อฟอร์ม",
              "เปลี่ยน font ใน confirm alert",
              "เพิ่มวันที่และรายละเอียดของแต่ละ version",
              "เปลี่ยนรูปแบบกราฟจากแนวนอนเป็นแนวตั้ง",
              "เพิ่มกราฟวงกลมในหน้า summary",
              "เพิ่มส่วนผลรวมรายโปรเจคจากกราฟ"
          ]
        },
        {
          version: "1.2",
          date: "2022/05/20",
          description: [
              "เพิ่มการค้นหาในช่อง employees",
              "ปรับข้อมูลการแสดงผลหน้า summary",
              "เพิ่มแบบฟอร์มการลา และหน้าอนุมัติการลา",
              "เปลี่ยนช่วงเวลาที่เลือกได้ของกราฟ"
          ]
        },
        {
          version: "1.3",
          date: "2022/05/25",
          description: [
              "เพิ่มการเช็ค tracking สำหรับการลา",
              "สรุปการ tracking หลังอนุมัติการลา"
          ]
        },
        {
          version: "1.4",
          date: "2022/10/22",
          description: [
              "แสดงเปอร์เซ็นในกราฟแท่ง",
              "แบ่งกราฟให้เป็นจำนวนชั่วโมงในการทำงานของโปรเจ็คนั้นๆ",
              "เรียงการแสดงกราฟจากมากไปน้อย",
              "ลงข้อมูลคนขอลาอนุมัติแล้ว ไปลงใน sheet หน้า ลงวันลาที่อนุมัติแล้ว"
          ]
        },
        {
          version: "1.5",
          date: "2023/01/05",
          description: [
              "อัปเดต sheet api ปี 2023",
              "แก้ไข Loading ค้าง ในหน้า Approve วันลา",
              "แก้ไขการบันทึกข้อมูล sheet ลงวันลาที่อนุมัติแล้ว ถ้าลามากกว่า 1 วัน ให้บันทึกแค่ 1 ครั้ง",
              "เพิ่มบันทึกข้อมูล วันที่ลาลงใน sheet ลงวันลาที่อนุมัติแล้ว"
          ]
        },
        {
          version: "1.6",
          date: "2023/03/30",
          description: [
              "เพิ่มการตรวจสอบจำนวนวันลาของพนักงานแต่ละคน",
              "เพิ่มการยกเลิกการลาของพนักงานในสถานะ pending",
              "เพิ่มการยกเลิกการลาโดยแอดมินหลังจาก approved ไปแล้ว",
              "เพิ่มการแสดงวันลาทั้งหมดทีได้ลาไปแล้วของพนักงานที่เลือกในหน้าของ Approval"
          ]
        },
        {
          version: "1.7",
          date: "2024/01/03",
          description: [
              "อัปเดต sheet api ปี 2024"
          ]
        },
        {
          version: "1.8",
          date: "2024/04/02",
          description: [
              "เพิ่มตำแหน่งใหม่ AE"
          ]
        },
        {
          version: "1.9",
          date: "2024/06/07",
          description: [
              "แยกกราฟ Summary รายเดือนและรายคน",
              "เพิ่มฟอร์มการขอ WFH",
              "ปรับ UX ให้ดีขึ้น"
          ]
        },
        {
          version: "2.0",
          date: "2024/07/19",
          description: [
              "เพิ่มการระบุ Password ก่อนใช้งาน",
              "เพิ่ม Comment Board",
              "Data encryption"
          ]
        },
        {
          version: "2.1",
          date: "2024/07/19",
          description: [
              "เพิ่ม Loading",
              "Fixes UI & Bugs",
              "Improvement",
              "ลงวันลาของปีถัดไป"
          ]
        },
        {
          version: "2.2",
          date: "2024/10/08",
          description: [
              "เพิ่ม OT Leave"
          ]
        }
      ]
    }
  },
  computed: {
    reversedVersions() {
      return [...this.versions].reverse();
    }
  },
}
</script>

<style scoped>
.chip-version {
  background: #42b983;
  color: #fff;
  border-radius: 12px;
  padding: 0 8px;
}
</style>